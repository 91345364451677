export default function LayoutCard({ title, children }) {
  return (
    <div className="w-full flex-col md:w-[70v] lg:max-w-[1000px] px-6">
      <h2 className="pt-5 text-2xl text-left lg:text-3xl md:pt-10">
        {title}
      </h2>
      <div className="flex flex-col w-full px-4 py-3 mt-3 bg-white rounded-lg lg:mt-5 lg:p-8 shadow-light">
        {children}
      </div>
    </div>
  );
}
