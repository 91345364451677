import { useEffect, useState } from 'react';
import { useZusUserConnectedStore } from '@/helpers/user-auth.js';
import { useZusModalStore, ModalWrapper } from '@/helpers/modal.js';

import {
  HeaderLogin,
  HeaderSignup,
  HeaderSignupStep2,
  HeaderConfirm,
  HeaderRegisterCompany,
  HeaderLoginCorpo,
} from '@/core/dynamic';

function LoginSignupContainer({ fromCorpo }) {
  const what = useZusUserConnectedStore((state) => state.what);
  const [currentWhat, setCurrentWhat] = useState([what]);
  const userAuthOpenModal = useZusUserConnectedStore((state) => state.userAuthOpenModal);

  const { setModal1 } = useZusModalStore.getState();

  function onClose() {
    if (fromCorpo) return true;
    return userAuthOpenModal();
  }

  useEffect(() => {
    const lastOne = currentWhat.pop();
    currentWhat.push(what);
    setCurrentWhat([...currentWhat]);
    if (what === 'login') {
      return setModal1(
        <ModalWrapper onClose={() => onClose()} delay={1}>
          <HeaderLogin callback={() => onClose()} />
        </ModalWrapper>,
      );
    }
    if (what === 'signup') {
      return setModal1(
        <ModalWrapper onClose={() => onClose()} delay={1}>
          <HeaderSignup callback={() => onClose()} />
        </ModalWrapper>,
      );
    }
    if (what === 'signupstep2') {
      return setModal1(
        <ModalWrapper noClose onClose={() => onClose()} delay={1}>
          <HeaderSignupStep2 callback={() => onClose()} />
        </ModalWrapper>,
      );
    }
    if (what === 'corpostep') {
      return setModal1(
        <ModalWrapper noClose onClose={() => onClose()} delay={1}>
          <HeaderLoginCorpo callback={() => onClose()} />
        </ModalWrapper>,
      );
    }
    if (what === 'companyconfirm') {
      return setModal1(
        <ModalWrapper noClose onClose={() => onClose()} delay={1}>
          <HeaderConfirm callback={() => onClose()} />
        </ModalWrapper>,
      );
    }
    if (what === 'registercompany') {
      return setModal1(
        <ModalWrapper noClose onClose={() => onClose()} delay={1}>
          <HeaderRegisterCompany callback={() => onClose()} lastStep={lastOne} />
        </ModalWrapper>,
      );
    }
    setModal1(null);

    return () => {};
  }, [what]);

  return null;
}

export default LoginSignupContainer;
